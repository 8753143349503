/**
 * @Copyright(c) 2017-2024 捷杰传感 
 * @Author（创建人） gujiandong
 * @Date（创建时间） 2024/5/10 14:28
 * @Description（类描述）: 
 * @Version（版本）: v1.0
 * @DemandAddress（需求地址）：
 */

import _ from 'lodash'
import { Vue, Component, Ref, Prop, } from 'vue-property-decorator'
import * as echarts from 'echarts'
import RadioOptions from "@/components/RadioOptions/index.vue"
import { IWaveSpectrumQuery } from '../types'
import { FrequencyTimerFilterImpl } from './FrequencyTimerFilter/FrequencyTimerFilterImpl'
import FrequencyTimerFilter from './FrequencyTimerFilter/FrequencyTimerFilter.vue'
import { ISamplingTimeData } from '@/api/moudules/wavefrom.types'
import { E_ChartTypeString, E_Eigenvalue } from '@/types'
import { ChartTypeString2Eigenvalue } from '@/constants/charts'
import 'echarts-gl'
import { EChartImpl } from '@/components/EChart/EChartImpl'
import { Toolbox } from '@/utils/echarts'
import { spectrum } from '@/charts/waveChartOption';
import { getAxialColor, getPrimaryColor } from '@/utils/color'
import { formatTitle2 } from '@/view_helper/wave'
import { toFixed } from '@/utils/math'
import ChartPopupOptions from '@/components/ChartPopupOptions.vue'

function makeQueryForm() {
    return {
        pageNum: 1,
        pageSize: 15,
    }
}

@Component({
    name: 'Waterfall',
    components: {
        RadioOptions,
        FrequencyTimerFilter,
        ChartPopupOptions,
    }
})
export class WaterfallImpl extends Vue {
    @Prop({
        type: Object,
    }) query: IWaveSpectrumQuery

    @Ref()
    frequencyTimerFilterRef: FrequencyTimerFilterImpl

    samplingTimes: ISamplingTimeData[] = []

    get SelectedWaveIds() {
        return _.map(this.samplingTimes, s => s.waveId)
    }

    onFrequencyTimerChange(value: ISamplingTimeData, checked: boolean) {
        if (checked) {
            this.samplingTimes = [...this.samplingTimes, value]
        } else {
            this.samplingTimes = _.filter(this.samplingTimes, s => s.waveId !== value.waveId)
        }
        this.updateChartWaterFall()
    }

    get Charts() {
        let res = [
            E_ChartTypeString.ACCELERATION_FREQUENCY,
            E_ChartTypeString.VELOCITY_FREQUENCY,
        ]
        res = [
            ...res,
            E_ChartTypeString.DISPLACEMENT_FREQUENCY,
            E_ChartTypeString.ENVELOPE_SIGNAL,
        ]
        return res
    }

    queryForm = makeQueryForm()

    // 搜索
    onSearch() {
        if (this.frequencyTimerFilterRef) {
            this.frequencyTimerFilterRef.onQuery()
        }
    }

    onReset() {
        if (this.frequencyTimerFilterRef) {
            this.frequencyTimerFilterRef.onReset()
        }
        this.samplingTimes = []
        this.waveChartRef.chart.setOption({}, true, true)
        this.chartWaterFallRef.chart.setOption({}, true, true)
    }

    selectChartType = E_ChartTypeString.ACCELERATION_FREQUENCY

    get SelectChartEigenvalue() {
        return ChartTypeString2Eigenvalue[this.selectChartType]
    }

    chartLabel(chart: E_ChartTypeString) {
        return this.$enumTrans.ChartTypeString(chart)
    }

    showDialog = false

    // 图表类型切换
    openOptions($event: MouseEvent) {
        $event.stopPropagation()
        this.showDialog = true;
    }
    changeOption(value) {
        this.selectChartType = value
        this.showDialog = false
        this.updateChartWaterFall()
    }
    closeOption() {
        this.showDialog = false;
    }

    @Ref()
    chartWaterFallRef: EChartImpl

    async requestFnChartWaterFall(chart: echarts.ECharts) {
        const tasks = _.map(this.samplingTimes, t => {
            return this.$api.waveform.queryWaveform({
                waveId: t.waveId,
                chartType: this.selectChartType,
            })
        })
        const results = await Promise.all(tasks)
        if (_.some(results, r => r.code !== 200)) {
            this.$message({
                type: 'error',
                message: this.$t('errors.serverError') as string
            })
            return
        }

        let series = _.flatMap(_.zip(results, this.samplingTimes), ([item, time]) => {
            const {
                dateTime,
                waveList,
                interval,
            } = item.data
            const radioX = interval ? interval : 1
            // const data = _.flatMap(waveList, wave => {
            //     const {
            //         x, y1, y2, y3,
            //     } = wave
            //     return [
            //         [radioX * x, dateTime, y1, time.waveId],
            //         [radioX * x, dateTime, y2, time.waveId],
            //         [radioX * x, dateTime, y3, time.waveId],
            //     ]
            // })
            return [{
                type: 'line3D',
                lineStyle: {
                    width: 4,
                },
                name: 'X',
                data: _.map(waveList, wave => [radioX * wave.x, dateTime, wave.y1, time.waveId]),
            }, {
                type: 'line3D',
                lineStyle: {
                    width: 4,
                },
                name: 'Y',
                data: _.map(waveList, wave => [radioX * wave.x, dateTime, wave.y2, time.waveId]),
            }, {
                type: 'line3D',
                lineStyle: {
                    width: 4,
                },
                name: 'Z',
                data: _.map(waveList, wave => [radioX * wave.x, dateTime, wave.y3, time.waveId]),
            },]
        })

        const color = [
            'rgb(245,82,53)',
            'rgb(255,187,48)',
            'rgb(249,217,112)',
            'rgb(247,251,48)',
            'rgb(177,248,53)',
            'rgb(56,174,215)',
            'rgb(169,191,226)',
            'rgb(192,100,217)',
            'rgb(94,120,137)',
        ]
        let unitZ = "";
        switch (this.selectChartType) {
            case E_ChartTypeString.ACCELERATION_FREQUENCY:
                unitZ = '(m/s²)';
                break
            case E_ChartTypeString.VELOCITY_FREQUENCY:
                unitZ = '(mm/s)'
                break
            case E_ChartTypeString.DISPLACEMENT_FREQUENCY:
                unitZ = '(μm)'
                break
            case E_ChartTypeString.ENVELOPE_SIGNAL:
                unitZ = '(gE)'
                break
        }
        let option = {
            toolbox: Toolbox,
            tooltip: {
                // valueFormatter: (value) => toFixed(value, 2),
                formatter(params) {
                    const {
                        value,
                        encode,
                    } = params
                    const x = toFixed(params.value[encode.x[0]], 2)
                    const date = value[encode.y[0]]
                    const res = toFixed(value[encode.z[0]], 2)
                    return params.marker + params.seriesName + ' ' + String(res) +
                        `<br/> date ${date} <br/> x   ${x}`
                },
            },
            legend: {
                right: "200px",
                textStyle: {
                    color: getPrimaryColor(),
                },
            },
            xAxis3D: {
                type: 'value',
                name: '',
                nameTextStyle: {
                    color: 'white',
                },
                axisLabel: {
                    color: getPrimaryColor(),
                }
            },
            yAxis3D: {
                type: 'category',
                name: '',
                nameTextStyle: {
                    color: 'white',
                },
                axisLabel: {
                    color: getPrimaryColor(),
                }
            },
            zAxis3D: {
                type: 'value',
                name: '',
                nameTextStyle: {
                    color: 'white',
                },
                axisLabel: {
                    color: getPrimaryColor(),
                    formatter(x) {
                        return x + unitZ
                    }
                }
            },
            grid3D: {
                boxWidth: 500,//三维场景高度
                boxHeight: 100,//三维场景高度
                boxDepth: 80,//三维笛卡尔坐标系组件在三维场景中的深度
                viewControl: {
                    projection: 'orthographic',
                    // rotateSensitivity: 0,
                    beta: 5,
                    alpha: 30
                }
            },
            series: series,
        }
        console.log(option)
        chart.setOption(option, true);
    }

    updateChartWaterFall() {
        this.chartWaterFallRef.request()
    }

    @Ref()
    waveChartRef: EChartImpl

    selectWaveId = null

    waveChartTime = null
    waveChartTitle = null
    async requestFnWaveChart(chart: echarts.ECharts) {
        const params = {
            mappingId: this.query.mappingId,
            chartType: this.SelectChartEigenvalue,
            waveId: this.selectWaveId
        }
        const res = await this.$api.wave.waveDetail(params)
        const { code, data } = res;
        if (code != 200 || null == data) {
            this.$message({
                message: this.$t('errors.noData') as string,
                type: 'success'
            });
            return;
        }
        this.waveChartTime = data.headInfo.waveTime
        this.waveChartTitle = formatTitle2(data.title)
        let option = spectrum(this, this.SelectChartEigenvalue, data.wave, data.interval) as any
        option.color = [getAxialColor('x'), getAxialColor('y'), getAxialColor('z')]
        if (this.SelectChartEigenvalue == E_Eigenvalue.EnvelopeSpectrum) {
            // 包络
            // option.xAxis.axisLabel = {
            //     interval: (index, value) => {
            //         if (index % 200 == 0) {
            //             return value;
            //         }
            //     },
            //     formatter: (value, index) => {
            //         const n = value.substr(0, value.length - 2);
            //         const u = value.substr(value.length - 2, value.length);
            //         return parseInt(n) + u;
            //     }
            // };
        } else {
            // option.xAxis.axisLabel = {
            //     interval: (index, value) => {
            //         for (let i = 0; i <= 10; i++) {
            //             if (index == 205 * i) {
            //                 return value;
            //             }
            //         }
            //     },
            //     formatter: (value, index) => {
            //         const n = value.substr(0, value.length - 2);
            //         const u = value.substr(value.length - 2, value.length);
            //         return parseInt(n) + u;
            //     }
            // };
        }
        chart.setOption(option, true, true);
    }

    updateWaveChart() {
        this.waveChartRef.request()
    }

    mounted() {
        this.chartWaterFallRef.chart.on('click', {
            seriesType: 'line3D',
            componentType: "series",
        }, ev => {
            let waveId = ev.data[3]
            this.selectWaveId = waveId
            this.updateWaveChart()
        })
    }
}
